<template>
  <div class="error-wrapper">
    <div class="error">
      <h1>204</h1>
      <p>No content</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "no-content"
}
</script>

<style lang="scss" scoped>
.error-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .error {
    text-align: center;

    h1 {
      color: #C00;
    }

    p {
      color: #333;
    }
  }
}
</style>